export const environment = {
  production: true,
  backendUrl: 'https://develop-calypso-admin.imagineapps.co/',
  apiDaptaURL: 'https://api.dapta.ai/api/calypso-del-caribe-169-817-8/',
  apiKey:'h6Y4J-12631411-eb41-4e62-958b-3dc5804ccffb-f',
  firebaseConfig: {
    apiKey: 'AIzaSyBFfJikc71dRhvSTgnFzF0Bqd0s2xN_bDU',
    authDomain: 'qmenu-qa.firebaseapp.com',
    databaseURL: 'https://qmenu-qa-default-rtdb.firebaseio.com',
    projectId: 'qmenu-qa',
    storageBucket: 'qmenu-qa.appspot.com',
    messagingSenderId: '809251249564',
    appId: '1:809251249564:web:e5591ce9a863958b9d3c59',
    measurementId: 'G-FVB5NBSFW4',
  },
  bucketInstance: {
    accessKeyId: "AKIAUT7IXVID57OIMHHA",
    secretAccessKey: "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",
    region: "us-east-2",
  },
};
