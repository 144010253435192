import { AfterViewChecked, SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { element } from 'protractor';
import { ActivatedRoute, Router } from '@angular/router';
import { SeoService } from 'src/app/core/services/seo.service';

import { ServiceRecetasService } from '../../../core/services/recetas/service-recetas.service';

@Component({
  selector: 'app-recetas-all',
  templateUrl: './recetas-all.component.html',
  styleUrls: ['./recetas-all.component.scss'],
})
export class RecetasAllComponent implements OnInit {
  modal: boolean = false;
  postTodas = 5;
  nameSelect: string;
  recetas: any[];
  // Lista de recetas global
  public recetasGlobales: any[] = [];
  // Recetas selected - Render list
  public recetasSelected: any[];
  // Loading recetas
  public mainLoading: boolean = true;
  // Recetas match - Todas las recetas que hicieron match
  public recetasMatched: any[] = [];
  public data: any;
  public categoriaSelected = 'Todas';

  /** Pagination variables */
  // Item's quantity per consult
  public itemsPerPage: number = 9;
  // current page in pagination
  public currentPage: number = 1;
  // Global total of items
  public totalItems: number = 0;
  public informText: string = 'Mostrando 0-0 de 0 resultados'
  // Options to sort products
  public orderOptions: string[] = ['Productos de la A - Z', 'Productos de la Z - A']; 

  cat = [];

  categorias = [
    {
      nombre: 'Todas',
      url: '../../../../assets/imgs/imgs-tests/categorias-todas.png',
    },
  ];

  constructor(
    public serviceRecetasService: ServiceRecetasService, 
    private router: Router, private seoService: SeoService
    ) {
    this.seoService.setSEO({
      title: 'Recetas',
      description: 'Descubre nuestras recetas Calypso del Caribe, únicas y deliciosas.'
    });

    const filters =[{ field: 'estado', operator: '==', value: 'Activa'}];
    this.serviceRecetasService.getRecipes('recetas', filters).then((res:any) => {
      res.forEach((element) => {
        this.recetasGlobales.push(element);
      });
      this.serviceRecetasService.selectedRecipe.subscribe( (selected) => {
        this.categoriaSelected = (selected) ? selected : 'Todas';
        if (this.totalItems && this.itemsPerPage && this.currentPage) {
          // Build string to show many items
          const startItem = (this.currentPage - 1) * this.itemsPerPage + 1;
          const endItem = Math.min(this.currentPage * this.itemsPerPage, this.totalItems);
          // Building string
          this.informText = "Mostrando " + startItem + "-" + endItem + " de " + this.totalItems + " resultados";          
        }
        this.onAllrecetas(this.categoriaSelected);
      });
    });

  }
  
  ngOnInit(): void {}

  /** Metodo para volver */
  back(): void {
    this.router.navigate(['']);
  }
  /** Metodo para ver todas */

  verTodas() {
    if (this.postTodas > 5) {
      this.postTodas = 5;
    } else {
      this.postTodas = 1000;
    }
  }
  /** Metodo para seleccionar todas las recetas */

  onAllrecetas(nombre): void {
    this.currentPage = 1;

    let lista = [];
    for (const receta of this.recetasGlobales) {
      if (nombre === 'Todas') {
        lista.push(receta);
      } else if (receta.categoria === nombre) {
        lista.push(receta);
      }
    }
    this.recetasMatched = JSON.parse(JSON.stringify(lista));
    this.onGetRendering();
  }

  // On paginate list
  onGetRendering() {
    this.mainLoading = true;
    this.recetasSelected = [];
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.totalItems = this.recetasMatched.length;
    this.recetasSelected = this.recetasMatched.slice(startIndex, endIndex);
    this.mainLoading = false;
  }

  // Goes to previous page
  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.onGetRendering();
    }
  }
  // Goes next page
  nextPage() {
    if (this.currentPage < Math.ceil(this.totalItems / this.itemsPerPage)) {
      this.currentPage++;
      this.onGetRendering();
    }
  }

  // Display an specific page
  onGoToPage(page: number) {
    this.currentPage = page;
    this.onGetRendering();
  }

  showFilter() {
    this.modal = !this.modal;
  }

  handleOrderButtonEvent(event: any) {
    switch (event) {
      case 'Productos de la A - Z':
        this.sortProducts(true, 'nombre');
        break;
      case 'Productos de la Z - A':
        this.sortProducts(false, 'nombre');
        break;
    }
  }

  sortProducts(ascendent: boolean, field: string) {
    const productToShowTemp = this.recetasSelected;
    for (let i = 0; i < productToShowTemp.length; i++) {
      for (let j = 0; j < productToShowTemp.length - 1; j++) {
        if (ascendent ?
          productToShowTemp[j][field] > productToShowTemp[j + 1][field] :
          productToShowTemp[j][field] < productToShowTemp[j + 1][field]) {
          let temp = productToShowTemp[j];
          productToShowTemp[j] = productToShowTemp[j + 1];
          productToShowTemp[j + 1] = temp;
        }
      }
    }
    this.recetasSelected = productToShowTemp;
  }  
}
