<div class="item" [ngStyle]="{ 'background-image': 'url(' + product.foto + ')' }">
  <div class="city-container">
    <p>{{ product.direccionSegmentada.ciudad }}</p>
  </div>
  <figure class="item-image">
    <img [src]="product.foto" alt="imagen tienda">
    <div class="city-container city-mobile">
      <p>{{ product.direccionSegmentada.ciudad }}</p>
    </div>
  </figure>
  <div class="item-info">
    <p class="item-info-title">{{ product.nombre }}</p>
    <div class="item-option">
      <figure>
        <svg class="icons-red" width="24" height="24" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g id="location_on" clip-path="url(#clip0_11132_5969)">
            <path id="Vector"
              d="M12 2C8.13 2 5 5.13 5 9C5 13.17 9.42 18.92 11.24 21.11C11.64 21.59 12.37 21.59 12.77 21.11C14.58 18.92 19 13.17 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z"
              fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_11132_5969">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </figure>
      <p class="pointer" (click)="openGoogleMapsAddress(product.lat, product.lng)">{{ product.direccionCompleta }}</p>
    </div>
    <!-- <div class="item-option">
      <figure>
        <svg class="icons-red" width="24" height="24" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g id="phone" clip-path="url(#clip0_11132_5974)">
            <path id="Vector"
              d="M19.23 15.2598L16.69 14.9698C16.08 14.8998 15.48 15.1098 15.05 15.5398L13.21 17.3798C10.38 15.9398 8.06004 13.6298 6.62004 10.7898L8.47004 8.93976C8.90004 8.50977 9.11004 7.90977 9.04004 7.29977L8.75004 4.77977C8.63004 3.76977 7.78004 3.00977 6.76004 3.00977H5.03004C3.90004 3.00977 2.96004 3.94977 3.03004 5.07977C3.56004 13.6198 10.39 20.4398 18.92 20.9698C20.05 21.0398 20.99 20.0998 20.99 18.9698V17.2398C21 16.2298 20.24 15.3798 19.23 15.2598Z"
              fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_11132_5974">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

      </figure>
      <a class="pointer" href="https://wa.me/{{ product.whatsapp }}" target="_blank">{{ product.whatsapp }}</a>
    </div> -->
    <div class="item-option">
      <figure>
        <svg class="icons-red" width="24" height="24" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g id="today" clip-path="url(#clip0_11132_5979)">
            <path id="Vector"
              d="M19 3H18V2C18 1.45 17.55 1 17 1C16.45 1 16 1.45 16 2V3H8V2C8 1.45 7.55 1 7 1C6.45 1 6 1.45 6 2V3H5C3.89 3 3.01 3.9 3.01 5L3 19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM18 19H6C5.45 19 5 18.55 5 18V8H19V18C19 18.55 18.55 19 18 19ZM8 10H11C11.55 10 12 10.45 12 11V14C12 14.55 11.55 15 11 15H8C7.45 15 7 14.55 7 14V11C7 10.45 7.45 10 8 10Z"
              fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_11132_5979">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </figure>
      <div class="d-flex flex-column">
        <p>Lunes a Sábados de {{ product.horaApertura | date:'shortTime' }} - {{ product.horaCierre | date:'shortTime'
          }}</p>
        <p>Domingos y festivos de {{ product.horarios.domingo.inicio | date:'shortTime' }} - {{
          product.horarios.domingo.final | date:'shortTime' }}</p>
      </div>
    </div>
    <div class="coverage-button">
      <button (click)="showMap = !showMap" type="button" class="btn btn-coverage" data-toggle="modal" data-target="#coverageMap">
        Ver cobertura
      </button>
    </div>
  </div>
</div>

<ng-container *ngIf="showMap">
  <div class="map-container">
    <div class="coverage-map">
      <button class="btn-close" (click)="showMap = !showMap">⨉</button>
      <agm-map 
        [latitude]="lat" 
        [longitude]="lng" 
        [zoom]="zoom" 
        (mapReady)="mapReady($event, product.zonaCobertura)" 
        style="height: 400px; width: 100%;">
      </agm-map>
    </div>
  </div>
  <div class="overlay"></div>
</ng-container>