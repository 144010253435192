import { Inject, Injectable, OnInit, PLATFORM_ID } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';
import { BehaviorSubject, Observable, interval, of } from 'rxjs';
import { map, take, mergeMap, finalize, catchError } from 'rxjs/operators';
import { Order } from 'src/app/interfaces/orders.interface';
import { User } from 'src/app/interfaces/user.interface';
import * as moment from 'moment';
import { Guid } from 'guid-typescript';
import { TransactionService } from '../transaction/transaction.service';
import { isPlatformBrowser } from '@angular/common';
import { SalePointsService } from '../sale-points/sale-points.service';
import { getSyntheticPropertyName } from '@angular/compiler/src/render3/util';
import { LocalstorageService } from '../localstorage.service';
import { HttpClient } from '@angular/common/http';
import { setFirebaseFilters } from 'src/app/global/utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  /**
   * Variable para guardar el id de la lista precios para promociones
   */
  private idListaPrecios: string;

  /**
   * Variale para guardar la lista de los TPVS de los lugares en los que aplica una promocion
   */
  private listaTpvs: string[];

  inStoreHours = {};
  /**
   *  Se guardarán los días así: {nombre: 'lunes', fecha: 'dd/MM/yy'}
   */
  inStoreDays = [];

  private completeUrl = `${environment.backendUrl}collections`;

  /**
   * BehaviroSubject to store all orders
   */
  private _orders = new BehaviorSubject<Order[]>([]);
  CREDIT_CARD_VALUE = 'Tarjeta';
  BANCOLOMBIA_PAYMENT = 'Pago Bancolombia';
  PAYMENT_TERMINAL = 'Datáfono';
  CASH_PAYMENT = 'Efectivo';
  PSE_PAYMENT = 'PSE';
  PENDING_STATUS = 'Pendiente';
  constructor(
    private afdb: AngularFireDatabase,
    private localStorage: LocalstorageService,
    private salePointsService: SalePointsService,
    private transactionsServices: TransactionService,
    private http: HttpClient
  ) {
    this.getCoastCities();
  }

  /**
   * Función para inicializar idListaPrecios y listaTpvs para revisar los precios
   */
  private getCoastCities() {
    this.afdb
      .object('/config/GrupoCiudades/ciudadesCosta')
      .valueChanges()
      .subscribe((ciudades) => {
        this.idListaPrecios = ciudades['idListaPrecios'];
        this.listaTpvs = ciudades['listaTpvs'];
      });
  }

  /**
   * Function to create a order object
   */
  createOrderObject(data): Order {
    const order: Order = {
      codigoRetiro: data.codigoRetiro,
      aplicoCodigo: data.aplicoCodigo,
      confirmacionBancolombia: data.confirmacionBancolombia,
      calificacion: data.calificacion || null,
      createdAt: data.createdAt,
      direccion: data.direccion,
      estado: data.estado,
      fechaEntrega: data.fechaEntrega,
      fechaInsert: data.fechaInsert,
      fechaVerificacionSiesa: data.fechaVerificacionSiesa,
      fechadeTransaccion: data.fechadeTransaccion,
      guidSql: data.guidSql,
      horaCancelado: data.horaCancelado || NaN,
      horaCreandoEnSiesa: data.horaCreandoEnSiesa,
      horaEnCurso: data.horaEnCurso || NaN,
      horario: data.horario,
      id: data.id,
      idMensajero: data.idMensajero || null,
      idUsuario: data.idUsuario,
      lat: data.lat || null,
      lng: data.lng || null,
      mensajero: data.mensajero || {},
      plataforma: data.plataforma,
      productosPedidos: data.productosPedidos,
      puntoDeVentaBodega: data.puntoDeVentaBodega,
      puntoDeVentaId: data.puntoDeVentaId,
      puntoDeVentaNombre: data.puntoDeVentaNombre,
      puntoDeVentaTpv: data.puntoDeVentaTpv,
      puntoDeVentaWhatsapp: data.puntoDeVentaWhatsapp,
      resultadoInsert: data.resultadoInsert,
      tipoPago: data.tipoPago,
      tipoEntrega: data.tipoEntrega,
      aplicoCreditos: data.aplicoCreditos,
      descuentoCredito: data.descuentoCredito,
      totalcobrarConCreditos: data.totalcobrarConCreditos,
      usuario: data.usuario,
      valorDomicilio: data.valorDomicilio,
      valorPedido: data.valorPedido,
      valorSubtotal: data.valorSubtotal,
      valorSubtotalTax: data.valorSubtotalTax,
      zona: data.zona,
    };
    return order;
  }
  /**
   * Get user orders
   * @param userId user id to search orders
   */
  getOrders(filters: any[] = [], orderBy: string = '', limit: number = 0): Observable<Order[]> {
    return this.http.get(`${this.completeUrl}/getCollection/pedidos`, { params: setFirebaseFilters(filters, orderBy, limit) })
      .pipe(
        map((res: any) => res.map((doc: any) => this.createOrderObject(doc))),
        catchError((error) => {
          console.log(`Error getting user orders: ${error}`);
          return of([]); // returns an empty observable in case of error
        })
      );
  }

  /**
   * Function to get order from firebase
   * @param orderId order id to do a request
   */
  getOrderFromFirebaseById(orderId: string): Observable<Order> {
    const filters = [{ field: 'id', operator: '==', value: orderId }];
    return this.getOrders(filters).pipe(
      map((orders: Order[]) => orders[0]),
      catchError((error) => {
        console.log(`Error getting order by id: ${error}`);
        return of(null); // returns an empty observable in case of error
      })
    );
  }

  /**
   * Update order data on firebase
   * @param order order to update on firebase
   */
  updateOrder(order) {
    try {
      return this.http.put(`${this.completeUrl}/updateCollection/pedidos/${order.id}`, order).toPromise();
    } catch (error) {
      console.log(`Error updating order: ${error}`);
    }
  }

  /**
   * Update order data on firebase only if the order has status: Pago Pendiente
   * @param order order to update on firebase
   */
  async payAndUpdateOrder(order): Promise<any> {
    try {
      const filters = [{ field: 'id', operator: '==', value: order.id }];
      this.getOrders(filters).subscribe((orderData: any) => {
        if (orderData.estado === 'Pago Pendiente') {
          return this.updateOrder(order);
        }
      });
    } catch (error) {
      console.log(`Error updating order: ${error}`);
    }
  }

  /**
   * Getter to all orders as observable
   */
  get orders(): Observable<Order[]> {
    return this._orders.asObservable();
  }

  /**
   * Function that proccess when a order is selected
   * @param id order id to do a request
   */
  getOrderById(id: string): Observable<Order> {
    return this.orders.pipe(
      take(1),
      map((orders) => {
        return {
          ...orders.find((order) => {
            return order.id === id;
          }),
        };
      })
    );
  }

  /**
   * Function that store a order in FireStore
   * @param  order object order
   */
  storeOrder(order): Observable<any> {
    try {
      return this.http.post(`${this.completeUrl}/postCollection/pedidos`, order).pipe(map((res) => res));
    } catch (error) {
      console.log(`Error storing: ${error}`);
    }
  }

  sendUpdate(order): any {
    try {
      for (const o in order) {
        if (order[o] === undefined) {
          delete order[o];
        }
      }
      return this.updateOrder(order)
    } catch (error) {
      console.log(`Error setting order update: ${error}`);
    }
  }
  /**
   * Almacena la orden en firebase
   * @param order objeto a guardar en firebase
   * @param user datos del usuario que hace la compra
   * @param cartInfo datos del carrito de compra y los productos
   * @param card datos de la tarjeta en caso de que sea necesaria
   * @param card datos de pse en caso de ser necesarios
   */
  async startCreateOrder(order: Order, user: User, cartInfo, card, pseData): Promise<Observable<any>> {
    try {
      const storeOrder = await this.storeOrder(order).toPromise();
      order.id = storeOrder.id;
      const siesaOrder = await this.createSiesaOrder(order, user, cartInfo, card, pseData).toPromise();
      return siesaOrder
    } catch (error) {
      console.log(`Error al guardar el pedido en firebase: ${error}`);
      return of({
        error: true,
        data: [`Error al guardar el pedido en firebase: ${error}`],
      });
    }
  }

  /**
   * Guarda el pedido en el back, donde luego guarda la informacion el SIESA
   * @param order Objeto con la info de la orden para crear datos en SIESA
   * @param user datos del usuario que hace la compra
   * @param cartInfo datos del carrito de compra y los productos
   * @param card datos de la tarjeta en caso de que sea necesaria
   * @param pseData datos de pse en caso de ser necesarios
   */
  createSiesaOrder(order: Order, user: User, cartInfo, card, pseData): Observable<any> {
    let generateGuid = Guid.create();
    const aux = generateGuid.toString();
    const fecha = moment().utc().format().split('+')[0];
    generateGuid = Guid.create();
    const aux2 = generateGuid.toString();
    let tipoPago = 'PAGO_EFECTIVO';
    if (
      order.tipoPago.toLowerCase() !== this.CASH_PAYMENT.toLowerCase() &&
      order.tipoPago.toLowerCase() !== this.BANCOLOMBIA_PAYMENT.toLowerCase()
    ) {
      tipoPago = 'PAGO_ONLINE';
    }
    let listPrice = null;
    if ('idListaPrecios' in user) {
      listPrice = user.idListaPrecios;
    } else if (this.listaTpvs.includes(order.puntoDeVentaTpv)) {
      listPrice = this.idListaPrecios;
    }

    let addressDelivery = user.direccion;

    if (order.tipoEntrega === 'Recogida') {
      addressDelivery = 'Recoger en tienda';
    }

    /**
     * Obtiene la información para facturación electrónica
     * @param isBilling define el monto
     * @param user usuario 
     * @param item item a evaluar
     * @returns 
     */
    function getInfoBilling(order, user, item) {
      if (item === 'dir') {
        if (order.tipoEntrega === 'Recogida') {
          return order.puntoDeVentaDireccion
        } else if (order.tipoEntrega === 'Domicilio') {
          return order.direccion
        }
      }
      if (user.facturacionElectronica.tipoPersona) {
        if (item == 'ind') {
          return '1'
        }
        if (item == 'firstLastName') {
          return 'Nan'
        }
        if (item == 'secondLastName') {
          return 'Nan'
        }
      } else {
        if (item == 'ind') {
          return '0'
        }
        if (item == 'firstLastName') {
          return user.facturacionElectronica.apellidoUno
        }
        if (item == 'secondLastName') {
          return user.facturacionElectronica.apellidoDos
        }
      }
    }

    const header = {
      tpv: user.puntoDeVentaTpv,
      fecha,
      guid: aux,
      indicativo: '57',
      telefono: user.celular,
      contacto: user.nombre,
      pais: '169',
      depto: user.puntoDeVentaDepto,
      barrio: user.puntoDeVentaBarrio,
      ciudad: user.puntoDeVentaCodMun,
      idListaPrecios: listPrice,
      direccion: addressDelivery,
      f_id_tipo_entraga: tipoPago,
      id_doc: user.facturacionElectronica.idFacturacion,
      ind_tipo_tercero: getInfoBilling(order, user, 'ind'),
      nombre_razon_social: user.facturacionElectronica.nombreFacturacion,
      apellido_uno: getInfoBilling(order, user, 'firstLastName'),
      apellido_dos: getInfoBilling(order, user, 'secondLastName'),
      email: user.facturacionElectronica.correo,
      telefono_facturacion: user.facturacionElectronica.celular,
      direccion_facturacion: getInfoBilling(order, user, 'dir'),
      id_tercero_vendedor: ''
    };

    if (user.vendedorAsociado && user.vendedorAsociado.nit) {
      header.id_tercero_vendedor = user.vendedorAsociado.nit;
    } else {
      delete header.id_tercero_vendedor
    }

    const orderSiesaData = { header, items: [] };

    /**
     * Función para enviar a Backend info de ID del producto, si es distribuidor o no
     * @param product producto a verificar
     * @returns id o prod_id según si es distribuidor o no
     */
    function getItem(product) {
      if (order.distribuidor !== "") {
        return product.id
      } else {
        return product.prod_id
      }
    }

    for (const prod of cartInfo.products) {
      const pedidoSql: any = {};
      // pedidoSql.tpv = 'TPV11102';
      // pedidoSql.fecha= moment().format(); //pendiente
      pedidoSql.guid = aux;
      pedidoSql.guidB = Guid.create().toString();
      pedidoSql.item = getItem(prod);
      pedidoSql.concepto = 1201;
      pedidoSql.motivo = '01';
      pedidoSql.unidad_medida = prod.unidadAbreviada;
      pedidoSql.cant_base = prod.quantity;
      pedidoSql.orden_padre = '-1';
      pedidoSql.orden = 6;
      pedidoSql.precio_uni = prod.precio;
      pedidoSql.vlr_bruto = prod.subtotal * prod.quantity;
      pedidoSql.familia = '50001_CALYPSO';
      pedidoSql.tipo_reg_preparacion = 0;
      pedidoSql.tipo_familia_item = 0;
      pedidoSql.id_llave_impuesto = prod.llaveImpositiva;
      pedidoSql.porcentaje_base = 100;
      pedidoSql.insertaIva = true;

      switch (prod.llaveImpositiva) {
        case '0001':
          pedidoSql.tasa = 19;
          break;
        case '0014':
          pedidoSql.tasa = 39;
          break;
        case '0009':
          pedidoSql.tasa = 29;
          break;
        case '4001':
          pedidoSql.tasa = 0;
          pedidoSql.insertaIva = false;
          break;
        case '5001':
          pedidoSql.tasa = 8;
          break;
        case '5002':
          pedidoSql.tasa = 19;
          break;
        case '2001':
          pedidoSql.tasa = 5;
          break;
        case '2007':
          pedidoSql.tasa = 19;
          break;
        case '2008':
          pedidoSql.tasa = 5;
          break;
        case '6002':
          pedidoSql.tasa = 0;
          break;
      }
      pedidoSql.vlr_uni = 0.0;
      pedidoSql.vlr_tot = prod.iva;
      pedidoSql.totalIva = prod.iva * prod.quantity;
      pedidoSql.ind_accion = 1;
      pedidoSql.ind_calculo = 1;
      pedidoSql.vlr_neto = prod.precio * prod.quantity;

      orderSiesaData.items.push(pedidoSql);
    }

    // Agrega el item domicilio al objeto de SIESA si tiene valor
    if (cartInfo.deliaryTax != 0) {
      const subDomi = (100 * cartInfo.deliveryTax) / 119;
      const prodDomi = {
        guid: aux,
        guidB: Guid.create().toString(),
        item: 1172,
        concepto: 1201,
        motivo: '01',
        unidad_medida: 'UND ',
        cant_base: 1,
        orden_padre: '-1',
        orden: 6,
        precio_uni: cartInfo.deliveryTax,
        vlr_bruto: subDomi,
        familia: '50001_CALYPSO',
        tipo_reg_preparacion: 0,
        tipo_familia_item: 0,
        id_llave_impuesto: '0006',
        porcentaje_base: 100,
        insertaIva: true,
        tasa: 19,
        vlr_uni: 0.0,
        vlr_tot: cartInfo.deliveryTax - subDomi,
        totalIva: cartInfo.deliveryTax - subDomi,
        ind_accion: 1,
        ind_calculo: 1,
        vlr_neto: cartInfo.deliveryTax,
      };

      orderSiesaData.items.push(prodDomi);
    }

    const self = this;
    return this.transactionsServices.createSiesaOrder(orderSiesaData).pipe(
      mergeMap(async (siesaResult: any) => {
        const insertResult = siesaResult.success;
        order.resultadoInsert = insertResult;
        order.guidSql = aux;
        const fechaInsert = moment().utc().format().split('+')[0];
        order.fechaInsert = fechaInsert;
        if (siesaResult.success) {
          order.horaCreandoEnSiesa = Date.now();
          self.updateOrderFirebase(order);
          let resCheck = await self.checkSiesa(aux, order, user, cartInfo, card, pseData).toPromise();
          return resCheck;
        } else if (!siesaResult.success && siesaResult.code == 'INVALID_PRICE') {
          order.estado = 'Cancelado';
          order.horaCancelado = Date.now();
          self.updateOrderFirebase(order);
          return of({
            error: true,
            data: [siesaResult.code, siesaResult.productsIssue],
          });
        }
        else {
          order.estado = 'Cancelado';
          order.horaCancelado = Date.now();
          self.updateOrderFirebase(order);
          return of({
            error: true,
            data: ['Error al insertar en Sql'],
          });
        }
      })
    );
  }

  /**
   * Actualiza la informacion del pedido en firebase.
   * @param order objeto de la orden para actualizar data en firebase
   */

  updateOrderFirebase(order: Order): void {
    this.sendUpdate(order)
      .then((res) => { })
      .catch((error) => {
        console.log(`Error al actualizar el pedido en firebase: ${error}`);
      });
  }

  /**
   * Verifica si el pedido fue incertado de forma correcta en SIESA, en caso contrario arroja un modal de error y lo cancela.
   * @param guid uid del objecto guardado en SIESA
   * @param order objeto con datos de la orden para actualizar firebase y/o dirigir al pago
   * @param user datos del usuario que hace la compra
   * @param cartInfo datos del carrito de compra y los productos
   * @param card datos de la tarjeta en caso de que sea necesaria
   * @param pseData datos de pse en caso de ser
   */
  checkSiesa(guid, order: Order, user: User, cartInfo, card, pseData): Observable<any> {
    const guidObj = { guid };
    const self = this;
    let verificationTries = 0;
    let resulVerifiationSiesa = null;
    return self.transactionsServices.getTransactionSiesa(guidObj).pipe(
      map(
        (result: any) => {
          verificationTries++;
          if (result.success && result.respuesta.length > 0) {
            resulVerifiationSiesa = true;
            if (result.respuesta[0].f9820_guid !== undefined) {
              if (result.respuesta[0].app_msg !== 'Transacción no exitosa') {
                // En esta parte debe montarse el descuento del credito.
                // self.restarCredito();
                const fechaVerificacion = moment().utc().format().split('+')[0];
                order.fechaVerificacionSiesa = fechaVerificacion;
                return self.pay(order, user, cartInfo, card, pseData);
              } else {
                order.estado = 'Cancelado';
                order.horaCancelado = Date.now();
                if (result.respuesta[0].app_desc_error && result.respuesta[0].app_desc_error !== 'Sin errores.') {
                  order.mensajeCancelacion = "Otro, " + result.respuesta[0].app_desc_error
                }
                self.updateOrderFirebase(order);
                const errorData = {
                  error: true,
                  data: [result.respuesta[0]],
                };
                return errorData;
              }
            } else {
              const errorData = {
                error: true,
                data: [result.respuesta[0]],
              };
              return errorData;
            }
          } else {
            return {};
          }
        },
        (error) => {
          if (!resulVerifiationSiesa) {
            order.estado = 'Cancelado';
            order.horaCancelado = Date.now();
            order.mensajeCancelacion = "Otro, No se pudo realizar verificación en SIESA"
            self.updateOrderFirebase(order);
            const errorData = {
              error: true,
              data: [`No se pudo verificar en SIESA`, error],
            };
            return errorData;
          }
        }
      )
    );
  }

  /**
   * Hace una consulta sobre el tipo de pago y redirige depediendo del tipo de pago
   * @param order objeto para procesar el pago
   * @param user datos del usuario que hace la compra
   * @param cartInfo datos del carrito de compra y los productos
   * @param card datos de la tarjeta en caso de que sea necesaria
   * @param pseData datos de pse en caso de ser necesarios
   */
  pay(order: Order, user: User, cartInfo, card, pseData): any {
    const paymentMethod = order.tipoPago.toLowerCase();
    if (paymentMethod === this.CASH_PAYMENT.toLowerCase() || paymentMethod === this.PAYMENT_TERMINAL.toLowerCase()) {
      order.estado = this.PENDING_STATUS;
      this.updateOrderFirebase(order);
      const content = this.modalLocal(order, user);
      const result = of(content);
      return result;
    } else if (paymentMethod === this.CREDIT_CARD_VALUE.toLowerCase()) {
      return this.processPayment(order, user, cartInfo, card);
    } else if (paymentMethod === this.PSE_PAYMENT.toLowerCase()) {
      return this.processPSEPayment(order, user, cartInfo, pseData);
    } else if (paymentMethod === this.BANCOLOMBIA_PAYMENT.toLowerCase()) {
      return this.setBancolombiaPayment(order, user);
    }
  }

  /**
   * Con la data de la order crea el objeto que se envia al back para procesar el pago.
   * @param order objeto con datos de la orden para extraer la informacion del pago
   * @param user datos del usuario que hace la compra
   * @param cartInfo datos del carrito de compra y los productos
   * @param card datos de la tarjeta para realizare pago
   */
  createEpaycoObject(order: Order, user: User, cartInfo, card): {} {
    const totalTaxes = order.productosPedidos.map((product) => {
      return product.iva * product.quantity;
    });
    const paymentData = {
      card: {
        token: card.token,
        epaycoId: card.epaycoId,
      },
      // Informacion de pago que se envia a epayco
      usuario: {
        name: card.name,
        document: card.cedula,
        email: user.email,
        bill: order.id,
        city: card.city,
        address: card.address,
        cell_phone: card.cell_phone,
        phone: card.phone,
        cedula: card.cedula,
      },
      // El subotal es realmente la base con la que se cobra el iva.
      payment: {
        cuotas: 1,
        precio: cartInfo.total + '',
        impuesto: totalTaxes + '',
        subtotal: cartInfo.subtotal + '',
      },
    };
    if (cartInfo.costoConcreditos) {
      (paymentData.payment.precio = cartInfo.costoConCreditos.toFixed(2) + ''),
        (paymentData.payment.subtotal = cartInfo.subtotal.toFixed(2) + '');
    }

    return paymentData;
  }

  /**
   * Con la data de la order crea el objeto PSE que se envia al back para procesar el pago.
   * @param order objeto con datos de la orden para extraer la informacion del pago
   * @param user datos del usuario que hace la compra
   * @param cartInfo datos del carrito de compra y los productos
   * @param pseData datos de PSE para la compra
   */
  createPSEObject(order: Order, user: User, cartInfo, pseData) {
    const paymentData = pseData;
    paymentData['invoice'] = order.id;
    pseData['description'] = 'Compra en Calypso del Caribe Web';
    pseData['value'] = cartInfo.total + '';
    pseData['tax_base'] = cartInfo.subtotal + '';
    pseData['tax'] = cartInfo.total / cartInfo.subtotal - 1 + '';
    if (cartInfo.costoConcreditos) {
      paymentData['value'] = cartInfo.costoConCreditos + '';
      paymentData['tax_base'] = '0';
      pseData['tax'] = cartInfo.costoConCreditos + '';
    }

    return paymentData;
  }

  /**
   * Procesa el pago y actualiza en firebase. Si la transacción es aceptada cambia el estado a
   * Pendiente, o Pago Pendiente si no es aceptada.
   * @param order objeto con datos del pedido para actualizar firebase o continuar con el pago
   * @param user datos del usuario que hace la compra
   * @param cartInfo datos del carrito de compra y los productos
   * @param card datos de la tarjeta en caso de que sea necesaria
   */
  processPayment(order: Order, user: User, cartInfo, card): any {
    const paymentData = this.createEpaycoObject(order, user, cartInfo, card);
    // Cambia el estado del pedido a Pago Pendiente
    order.estado = 'Pago Pendiente';
    // Actualiza el pedido en cloud de firebase
    this.updateOrderFirebase(order);
    return this.transactionsServices.processPayment(paymentData).pipe(
      map((paymentResult: any) => {
        order.transaccionTarjeta = paymentResult || null;
        // Actualiza el pedido en cloud de firebase
        this.updateOrderFirebase(order);
        if (paymentResult.payment?.respuesta === 'Aceptada') {
          order.estado = this.PENDING_STATUS;
          this.updateOrderFirebase(order);
          return this.modalLocal(order, user, paymentResult);
        } else if (paymentResult.payment?.respuesta === this.PENDING_STATUS) {
          // TODO Falta hacer la revisión de esta transacción en el back para cambiar el estado
          // TODO Falta crear la alerta que muestre que la transacción está pendiente
          return this.modalLocal(order, user, paymentResult);
        } else {
          order.estado = 'Cancelado';
          // Actualiza el pedido en cloud de firebase
          this.updateOrderFirebase(order);
          const errorData = {
            error: true,
            data: ['', 'Tu pago no se realizó con éxito, la transacción ha sido rechazada. Inténtalo nuevamente.'],
          };
          return errorData;
        }
      })
    );
  }

  /**
   * Procesa el pago PSE y actualiza en firebase
   * @param order objeto con datos del pedido para actualizar firebase o continuar con el pago
   * @param user datos del usuario que hace la compra
   * @param cartInfo datos del carrito de compra y los productos
   * @param pseData datos de pago de PSE
   */
  processPSEPayment(order: Order, user: User, cartInfo, pseData): any {
    const paymentData = this.createPSEObject(order, user, cartInfo, pseData);
    // Cambia el estado del pedido a Pago Pendiente
    order.estado = 'Pago Pendiente';
    // Actualiza el pedido en cloud de firebase
    this.updateOrderFirebase(order);
    return this.transactionsServices.processPSEPayment(paymentData).pipe(
      map((paymentResult: any) => {
        order.pseData = paymentResult?.payment?.data || null;
        // Actualiza el pedido en cloud de firebase
        this.updateOrderFirebase(order);
        if (
          paymentResult.success &&
          paymentResult.payment?.success &&
          (paymentResult.payment?.data?.estado !== 'Rechazada' || paymentResult.payment?.data?.estado !== 'Fallida')
        ) {
          return this.modalLocal(order, user, paymentResult);
        } else {
          order.estado = 'Cancelado';
          // Actualiza el pedido en cloud de firebase
          this.updateOrderFirebase(order);
          const errorData = {
            error: true,
            data: ['', 'Tu pago no se realizó con éxito, la transacción ha sido rechazada. Inténtalo nuevamente.'],
          };
          return errorData;
        }
      })
    );
  }

  /**
   * Actualiza el estado del pedido en firebase para pago bancolombia
   * @param order objeto con datos del pedido para actualizar firebase o continuar con el pago
   */
  setBancolombiaPayment(order: Order, user: User): any {
    // Cambia el estado del pedido a Pago Pendiente
    order.estado = 'Pago Pendiente';
    // Actualiza el pedido en cloud de firebase
    this.updateOrderFirebase(order);
    const content = this.modalLocal(order, user);
    const result = of(content);
    return result;
  }

  /**
   * Se pregunta por el metodo de pago y se almacena la ultima informacion del pedido.
   * @param order objeto con datos del pedido para abjuntar data del pago y continuar a crear la transacción
   * @param user datos del usuario que hac la compra
   * @param paymentResult objeto con el resultado del pago en caso de ser pago con tarjeta para crear la transacción
   */
  modalLocal(order: Order, user: User, paymentResult?): any {
    order.idUsuario = user.uid;
    order.usuario = user;
    order.estado = this.PENDING_STATUS;
    order.tipoPago = order.tipoPago;
    // Si el pedido se hace por una consigancion de Bancolombia, este tendra que un parametro que indica si ya se confirmo la transferencia.

    order.createdAt = Date.now();
    if (user.puntoDeVentaTpv !== 'TPV00602') {
      order.zona = user.puntoDeVentaTpv;
    } else {
      order.zona = 'a';
    }
    order.direccion = user.direccion;
    return { order, paymentResult };
  }

  /**
   * Crea los horarios de recogida en punto disponibles y los almacena en
   * @param horario horarios del punto de venta (horarios pensados para domicilio)
   * @param hApertura Hora de apertura del punto de venta (para cálculo de horas de entrega)
   * @param hCierrre Hora de cierre del punto de venta (para cálculo de horas de entrega)
   */
  async establecerHorarioInStore(horario, ventanaDias, diaInventario) {
    this.cleanDaysAndHours();
    const checkMoment = moment().locale('es');
    for (let i = 0; i < ventanaDias; i++) {
      let nameDay = checkMoment.format('dddd').normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      let horaInicio;
      let horaFin;
      let weekday = checkMoment.isoWeekday();
      // Verifica si el día revisado es festivo o no
      if (await this.getIfFestivo(checkMoment)) {
        weekday = 8;
      }

      // Asigna las horas correctas dependiendo del día
      ({ horaInicio, horaFin } = this.assignHours(weekday, horaInicio, horaFin, horario, nameDay));

      // Calcula la hora inicial de dsiponibilidad si es el mismo día
      if (i === 0) {
        const minutos = checkMoment.minute();
        horaInicio = checkMoment.hour() + (minutos > 15 ? 2 : 1);
      }

      // Genera la lista de horas del día en revisión

      const hours = [];
      for (let hora = horaInicio; hora < horaFin; hora += 2) {
        if (hora + 2 <= horaFin) {
          hours.push(hora + ':00 - ' + (hora + 2) + ':00');
        } else if (hora + 1 <= horaFin) {
          hours.push(hora + ':00 - ' + (hora + 1) + ':00');
        }
      }

      const fecha = checkMoment.format('DD/MM/yy');
      let nombre = checkMoment.format('dddd');

      // Se cambia o se añaden decoraciones al nombre del día
      if (i === 0) {
        nombre = 'Hoy';
      }
      if (weekday === 8) {
        nombre += ' (festivo)';
      }

      // Guarda la información de los horarios disponibles si y sólo si hay horas disponibles
      if (fecha !== diaInventario) {
        if (horaFin !== 0 && hours.length > 0) {
          this.inStoreDays.push({ nombre, fecha });
          this.inStoreHours[fecha] = hours;
        }
      }

      // Habilita la opción de mañana si hoy no hay más horario
      if (this.inStoreDays.length === 0) {
        ventanaDias++;
      }
      // Siguiente día
      checkMoment.add(1, 'd');
    }
    let respDays = [...this.inStoreDays];
    let respHours = { ...this.inStoreHours };
    this.cleanDaysAndHours();
    return { respDays, respHours };
  }

  cleanDaysAndHours() {
    this.inStoreDays = [];
    this.inStoreHours = {};
  }

  /**
   * Function to determine the time of the point-of-sale schedule
   * @param weekday Number of the day in a week, holiday is 8
   * @param horaInicio Opening Sale point
   * @param horaFin Closing Sale Point
   * @param horario the schedule of the Sale Point
   * @param todayDay Actual Day
   * @returns Opening and Closing hours of the actual day
   */
  assignHours(weekday: number, horaInicio: any, horaFin: any, horario: any, todayDay: string) {
    let selectedDay = 0
    if (weekday === 8) {
      horaInicio = horario.festivo.inicio;
      horaFin = horario.festivo.final;
    } else {
      for (let i in horario) {
        if (i == todayDay.normalize('NFD').replace(/[\u0300-\u036f]/g, "")) {
          horaInicio = horario[i].inicio
          horaFin = horario[i].final
          selectedDay++
          break;
        }
      }
      if (selectedDay === 0) {
        horaInicio = horario.entreSemana.inicio;
        horaFin = horario.entreSemana.final;
      }
    }
    return { horaInicio, horaFin };
  }
  /**
   * Funcion que consulta si el dia es festivo o no.
   */
  getIfFestivo(day?: moment.Moment): Promise<boolean> {
    return new Promise((resolve) => {
      this.salePointsService.getHollidays().subscribe((data) => {
        let checkDay = moment().format('YYYY-MM-DD');
        if (day) {
          checkDay = day.format('YYYY-MM-DD');
        }
        let festivo = false;
        const days = data.respuesta;
        for (const tDay of days) {
          if (tDay.holiday === checkDay) {
            festivo = true;
            break;
          }
        }
        if (festivo) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  /**
   * Calcula la fecha de entrega
   */
  getDeliveryDate(val): any {
    let date: moment.Moment;
    date = moment(val, 'DD/MM/YYYY').lang('es');
    return date.format('dddd') + ' ' + date.format('LL');
  }

  async getCostoProductosPromo() {
    let prodsCostosRef = await this.afdb.object('/config/costoProductosPromo').query.ref.once('value');
    let prodsCostos = prodsCostosRef.val();
    return prodsCostos;
  }

  async getProductosMatchPromo() {
    let prodsRef = await this.afdb.object('/config/productosMatchPromo').query.ref.once('value');
    let prods = prodsRef.val();
    return prods;
  }
}
