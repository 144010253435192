<ng-container *ngIf="!isB2B">
  <div class="card h-100" [style.width.px]="home ? 440 : 'auto'" *ngIf="product.precio != 9 || product.precio >= 1000">
    <div class="card-body">
      <div class="row m-0 h-100">
        <div class="col-5 image-container">
          <div class="pound-tag-desc" *ngIf="product.descuento">{{product.porcentaje_descuento}}% Dcto.</div>
          
          <div style="cursor: pointer;" (click)="getProductUrl()">
            <img src="{{ product.imagen }}" class="images" loading="lazy" (error)="defaultImage($event)"/>
            <div class="variable-weight-tag" *ngIf="product.pesoVariable">Peso variable</div>
            <div class="pound-tag" *ngIf="product.pesoVariable">{{ (product.pesoGramosVariable / 1000) * 2 }} Lib.
              aprox.</div>
          </div>
        </div>
        <div class="col-7 d-flex flex-column justify-content-between">
          <div style="cursor: pointer;" (click)="getProductUrl()">
            <div class="info-container">
              <span class="product-name">
                {{ product.nombre }}
              </span>
              <span class="product-weight" *ngIf="product.pesoVariable">
                {{ product.pesoGramosVariable }}g - $ {{ costForGram | number }}/g
              </span>
              <span class="product-weight" *ngIf="!product.pesoVariable">
                {{ product.pesoGramos }}g - $ {{ costForGram | number }}/g
              </span>
              <span class="product-weight" *ngIf="product.pesoVariable">Peso aprox. {{ product.pesoGramosVariable / 1000
                }} Kg</span>
              <span class="product-price" *ngIf="product.pesoVariable && spSelected">
                {{ pricePerPound(product.precio, product.pesoGramosVariable, product.pesoGramos) | customCurrency }} Libra
              </span>
              <span class="product-price" *ngIf="!product.pesoVariable && spSelected">
                {{ product.precio | customCurrency }}
              </span>
            </div>
          </div>
          <div class="row-btn-quantity" *ngIf="product.cobertura && spSelected">
            <div *ngIf="product.add" class="col-quantity">
              <button class="remove" (click)="removeProduct()">
                <i class="fa fa-minus btn-icon"></i>
              </button>
              <button class="quantity">{{ product.quantity }}</button>
              <button class="add" (click)="moreOneProduct()">
                <i class="fas fa-plus btn-icon"></i>
              </button>
            </div>
            <button *ngIf="product.quantity === 0" (click)="addProduct()" type="button" class="btn card-button">
              <i class="fas fa-plus btn-icon"></i> Agregar
            </button>
          </div>
          <ng-container *ngIf="!checkCobertura && !product.cobertura">
            <div class="row-btn-quantity">
              <button *ngIf="product.quantity === 0" (click)="getProductUrl()" type="button" class="btn card-button">
                <i class="fas fa-plus btn-icon"></i> Agregar
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Card para productos de clientes B2B -->
<ng-container *ngIf="isB2B">
  <div class="card h-100" [style.width.px]="home ? 440 : 'auto'" *ngIf="product.precio != 9 || product.precio >= 1000">
    <div class="card-body">
      <div class="row m-0 h-100">
        <div class="col-5 image-container">
          <div style="cursor: pointer;" (click)="getProductUrl()">
            <img src="{{ product.imagen }}" class="images" loading="lazy" />
            <div class="variable-weight-tag" *ngIf="product.pesoVariable">Peso variable</div>
          </div>
        </div>
        <div class="col-7 d-flex flex-column justify-content-between">
          <div style="cursor: pointer;" (click)="getProductUrl()">
            <div class="info-container b2b-info">
              <span class="b2b-price">
                {{ product.precio | customCurrency }} / {{ product.unidadAbreviada }}
              </span>
              <span class="b2b-label" *ngIf="product.pesoVariable">
                {{ product.pesoGramosVariable }}g - ($ {{ costForGram | number }}/g)
              </span>
              <span class="b2b-label" *ngIf="!product.pesoVariable">
                {{ product.pesoGramos }}g - ($ {{ costForGram | number }}/g)
              </span>
              <span class="b2b-name">
                {{ product.nombre }}
              </span>
              <span class="b2b-label" style="font-size: 16px;" *ngIf="product.pesoVariable && spSelected">
                Desde {{ product.pesoGramosVariable }}g
              </span>
            </div>
          </div>
          <div class="row-btn-quantity" style="gap: 8px;" *ngIf="product.cobertura && spSelected">
            <div *ngIf="product.add" class="d-flex">
              <button class="add-b2b" (click)="removeQuantityProduct()">
                <img src="../../../../assets/icons/shopping-cart/delete.svg" alt="trash can icon">
              </button>
            </div>
            <input type="number" min="0" max="999" class="cantidad-b2b" [(ngModel)]="quantityProduct"
              placeholder="Cantidad" [value]="(product.quantity > 0) ? product.quantity : ''"
              (ngModelChange)="changeQuantityProduct()" maxlength="3" (input)="validateInput($event)">
            <button *ngIf="product.quantity === 0 || !checkProductIcon" (click)="addQuantityProduct()" type="button"
              class="btn add-b2b">
              <img src="../../../../assets/imgs/Img-our-products/add_product.svg" alt="add product icon">
            </button>
            <button *ngIf="product.quantity > 0 && checkProductIcon" type="button" class="btn add-b2b">
              <img src="../../../../assets/icons/shopping-cart/check-product.svg" alt="check product icon">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>